<template>
  <ViewTemplate title="Add/Edit patient">
    <Dialog
      header="Edit patient"
      :visible="mode == PatientMode.EDIT_CONFIRM"
      :modal="true"
      :closable="false"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
        <span>
          Patient is already in the database, do you want to edit them?
        </span>
      </div>
      <template #footer>
        <Button
          label="No"
          icon="pi pi-times"
          @click="closingEditPatDialog(false)"
          class="p-button-rounded p-button-danger"
        />
        <Button
          label="Yes"
          icon="pi pi-check"
          @click="closingEditPatDialog(true)"
          class="p-button-rounded p-button-success"
          autofocus
        />
      </template>
    </Dialog>
    <Message v-for="alert in alerts" :key="alert.id" severity="warn">
      {{ alert.msg }}
    </Message>
    <Message
      v-if="mode == PatientMode.OTHER_TC"
      severity="error"
      :closable="false"
    >
      You can't edit this patient, they're already registered in a different TC.
      Contact your registry at
      <a href="mailto:kantasolurekisteri@veripalvelu.fi">
        kantasolurekisteri@veripalvelu.fi
      </a>
    </Message>
    <Message
      v-if="mode == PatientMode.DUPLICATE"
      severity="error"
      :closable="false"
    >
      This patient is still in pending, please contact the registry.
    </Message>
    <Message v-if="validationMap.get('patientid1')" severity="info">
      Please fill T-number
    </Message>
    <TabView class="p-mb-3 p-shadow-1" @input="valid">
      <TabPanel header="ID">
        <div class="p-grid p-ai-start">
          <div class="p-col-12 p-lg-6 p-grid p-ml-2">
            <Field label="T-number" :bind="getFixedProps(idWidth)" required>
              <InputText
                v-bind="getProps('patientid1', 'p-col-4 p-mb-2 p-mr-1')"
                v-model="selected.patientid1"
                type="text"
              />
              <Button
                label="Confirm"
                class="p-col-fixed p-mb-2 p-button-rounded p-button-success"
                style="width: 75px;"
                :disabled="!disable"
                @click="confirm"
              />
            </Field>
            <Field label="Personal number" :bind="getFixedProps(idWidth)">
              <InputText
                v-bind="getProps('personalnumber', idclass)"
                v-tooltip.focus="getTooltip('personalnumber')"
                v-model="selected.personalnumber"
                type="text"
              />
            </Field>
            <Field label="Last Name" :bind="getFixedProps(idWidth)" required>
              <InputText
                v-bind="getProps('lastname', idclass)"
                v-tooltip.focus="getTooltip('lastname')"
                v-model="selected.lastname"
                type="text"
              />
            </Field>
            <Field label="First Name" :bind="getFixedProps(idWidth)" required>
              <InputText
                v-bind="getProps('firstname', idclass)"
                v-tooltip.focus="getTooltip('firstname')"
                v-model="selected.firstname"
                type="text"
              />
            </Field>
            <Field label="Birth Name" :bind="getFixedProps(idWidth)">
              <InputText
                v-bind="getProps('birthname', idclass)"
                v-tooltip.focus="getTooltip('birthname')"
                v-model="selected.birthname"
                type="text"
              />
            </Field>
            <Field label="Birth Date" :bind="getFixedProps(idWidth)" required>
              <Calendar
                v-bind="getProps('birthdate', idclass)"
                v-tooltip.focus="getTooltip('birthdate')"
                v-model="birthdate"
                monthNavigator
                yearNavigator
                :yearRange="yearRange"
                @date-select="valid"
              />
            </Field>
            <Field label="Ethnicity" :bind="getFixedProps(idWidth)">
              <Dropdown
                v-bind="getProps('ethnicity', idclass)"
                v-tooltip.focus="getTooltip('ethnicity')"
                v-model="selected.ethnicity"
                :options="Ethnicity.values"
                optionLabel="name"
                optionValue="code"
                :filter="true"
                @change="valid"
              />
            </Field>
          </div>
          <div class="p-col-12 p-lg-6 p-grid p-ml-2">
            <Field label="Gender" :bind="getFixedProps(idWidth)" required>
              <Dropdown
                v-bind="getProps('gender', idclass)"
                v-tooltip.focus="getTooltip('gender')"
                v-model="selected.gender"
                :options="Gender.values.filter(v => v != Gender.UNKNOWN)"
                optionLabel="name"
                optionValue="code"
                @change="valid"
              />
            </Field>
            <Field label="Weight (kg)" :bind="getFixedProps(idWidth)">
              <InputText
                v-bind="getProps('weight', idclass)"
                v-tooltip.focus="getTooltip('weight')"
                v-model="selected.weight"
                type="text"
              />
            </Field>
            <Field label="Height (cm)" :bind="getFixedProps(idWidth)">
              <InputText
                v-bind="getProps('height', idclass)"
                v-tooltip.focus="getTooltip('height')"
                v-model="selected.height"
                type="text"
              />
            </Field>
            <Field label="ABO" :bind="getFixedProps(idWidth)">
              <Dropdown
                v-bind="getProps('abo', idclass)"
                v-tooltip.focus="getTooltip('abo')"
                v-model="selected.abo"
                :options="Abo.values"
                optionLabel="name"
                optionValue="code"
                @change="valid"
              />
            </Field>
            <Field label="Rh" :bind="getFixedProps(idWidth)">
              <Dropdown
                v-bind="getProps('rh', idclass)"
                v-tooltip.focus="getTooltip('rh')"
                v-model="selected.rh"
                :options="Rh.values"
                optionLabel="name"
                optionValue="code"
                @change="valid"
              />
            </Field>
          </div>
        </div>
      </TabPanel>
      <TabPanel header="Medical" :disabled="disable">
        <div class="p-grid p-ml-2">
          <Field label="DG EMDIS" :bind="getFixedProps(medicalWidth)" required>
            <Dropdown
              v-bind="getProps('dgemdis', medicalclass)"
              v-tooltip.focus="getTooltip('dgemdis')"
              v-model="selected.dgemdis"
              :options="DgEmdis.values"
              optionLabel="name"
              optionValue="code"
              @change="valid"
            />
          </Field>
          <Field
            v-if="
              [DgEmdis.OL.code, DgEmdis.OM.code, DgEmdis.OND.code].includes(
                selected.dgemdis
              )
            "
            label="DG Text"
            :bind="getFixedProps(medicalWidth)"
            required
          >
            <InputText
              v-bind="getProps('dgtext', medicalclass)"
              v-tooltip.focus="getTooltip('dgtext')"
              v-model="selected.dgtext"
              type="text"
            />
          </Field>
          <Field label="Date of DG" :bind="getFixedProps(medicalWidth)">
            <Calendar
              v-bind="getProps('dateofdg', medicalclass)"
              v-tooltip.focus="getTooltip('dateofdg')"
              v-model="dateofdg"
              monthNavigator
              yearNavigator
              :yearRange="yearRange"
              @date-select="valid"
            />
          </Field>
          <Field label="Physician" :bind="getFixedProps(medicalWidth)">
            <InputText
              v-bind="getProps('physician', medicalclass)"
              v-tooltip.focus="getTooltip('physician')"
              v-model="selected.physician"
              type="text"
            />
          </Field>
          <Field label="Disease phase" :bind="getFixedProps(medicalWidth)">
            <Dropdown
              v-bind="getProps('diseasephase', medicalclass)"
              v-tooltip.focus="getTooltip('diseasephase')"
              v-model="selected.diseasephase"
              :options="Dispha.values"
              optionLabel="name"
              optionValue="code"
              @change="valid"
            />
          </Field>
          <Field label="CMV" :bind="getFixedProps(medicalWidth)">
            <Dropdown
              v-bind="getProps('cmv', medicalclass)"
              v-tooltip.focus="getTooltip('cmv')"
              v-model="selected.cmv"
              :options="Cmv.values"
              optionLabel="name"
              optionValue="code"
              @change="valid"
            />
          </Field>
          <Field
            label="Search Scope"
            :bind="getFixedProps(medicalWidth)"
            required
          >
            <Dropdown
              v-bind="getProps('searchscope', medicalclass)"
              v-tooltip.focus="getTooltip('searchscope')"
              v-model="selected.searchscope"
              :options="SearchScope.values"
              optionLabel="name"
              optionValue="code"
              @change="valid"
            />
          </Field>
          <span class="p-col-12 p-text-left p-text-bold">
            Patient Status
          </span>
          <Field label="Status" :bind="getFixedProps(medicalWidth)">
            <Dropdown
              v-bind="getProps('status', medicalclass)"
              v-tooltip.focus="getTooltip('status')"
              v-model="selected.status"
              :options="Status.values"
              optionLabel="name"
              optionValue="code"
              @change="valid"
            />
          </Field>
          <Field
            label="Death date/status chng. date"
            :bind="getFixedProps(medicalWidth)"
          >
            <Calendar
              v-bind="getProps('statusdate', medicalclass)"
              v-tooltip.focus="getTooltip('statusdate')"
              v-model="statusdate"
              monthNavigator
              yearNavigator
              :yearRange="yearRange"
              @date-select="valid"
            />
          </Field>
          <Field label="Comment" :bind="getFixedProps(medicalWidth)">
            <InputText
              v-bind="getProps('comment', medicalclass)"
              v-tooltip.focus="getTooltip('comment')"
              v-model="selected.comment"
              type="text"
            />
          </Field>
        </div>
      </TabPanel>
      <TabPanel header="DNA" :disabled="disable">
        <div class="p-grid p-ai-start p-ml-2">
          <Field label="" :bind="getFixedProps(dnaWidth)" class="p-text-center">
            <span :class="dnaclass">1</span>
            <span :class="dnaclass">2</span>
          </Field>
          <Field label="A" :bind="getFixedProps(dnaWidth)">
            <InputText
              v-bind="getProps('a1', dnaclass)"
              v-tooltip.focus="getTooltip('a1')"
              v-model="selected.a1"
              type="text"
            />
            <InputText
              v-bind="getProps('a2', dnaclass)"
              v-tooltip.focus="getTooltip('a2')"
              v-model="selected.a2"
              type="text"
            />
          </Field>
          <Field label="B" :bind="getFixedProps(dnaWidth)">
            <InputText
              v-bind="getProps('b1', dnaclass)"
              v-tooltip.focus="getTooltip('b1')"
              v-model="selected.b1"
              type="text"
            />
            <InputText
              v-bind="getProps('b2', dnaclass)"
              v-tooltip.focus="getTooltip('b2')"
              v-model="selected.b2"
              type="text"
            />
          </Field>
          <Field label="C" :bind="getFixedProps(dnaWidth)">
            <InputText
              v-bind="getProps('c1', dnaclass)"
              v-tooltip.focus="getTooltip('c1')"
              v-model="selected.c1"
              type="text"
            />
            <InputText
              v-bind="getProps('c2', dnaclass)"
              v-tooltip.focus="getTooltip('c2')"
              v-model="selected.c2"
              type="text"
            />
          </Field>
          <Field label="DRB1" :bind="getFixedProps(dnaWidth)">
            <InputText
              v-bind="getProps('drb11', dnaclass)"
              v-tooltip.focus="getTooltip('drb11')"
              v-model="selected.drb11"
              type="text"
            />
            <InputText
              v-bind="getProps('drb12', dnaclass)"
              v-tooltip.focus="getTooltip('drb12')"
              v-model="selected.drb12"
              type="text"
            />
          </Field>
          <Field label="DRB3" :bind="getFixedProps(dnaWidth)">
            <InputText
              v-bind="getProps('drb31', dnaclass)"
              v-tooltip.focus="getTooltip('drb31')"
              v-model="selected.drb31"
              type="text"
            />
            <InputText
              v-bind="getProps('drb32', dnaclass)"
              v-tooltip.focus="getTooltip('drb32')"
              v-model="selected.drb32"
              type="text"
            />
          </Field>
          <Field label="DRB4" :bind="getFixedProps(dnaWidth)">
            <InputText
              v-bind="getProps('drb41', dnaclass)"
              v-tooltip.focus="getTooltip('drb41')"
              v-model="selected.drb41"
              type="text"
            />
            <InputText
              v-bind="getProps('drb42', dnaclass)"
              v-tooltip.focus="getTooltip('drb42')"
              v-model="selected.drb42"
              type="text"
            />
          </Field>
          <Field label="DRB5" :bind="getFixedProps(dnaWidth)">
            <InputText
              v-bind="getProps('drb51', dnaclass)"
              v-tooltip.focus="getTooltip('drb51')"
              v-model="selected.drb51"
              type="text"
            />
            <InputText
              v-bind="getProps('drb52', dnaclass)"
              v-tooltip.focus="getTooltip('drb52')"
              v-model="selected.drb52"
              type="text"
            />
          </Field>
          <Field label="DQB1" :bind="getFixedProps(dnaWidth)">
            <InputText
              v-bind="getProps('dqb11', dnaclass)"
              v-tooltip.focus="getTooltip('dqb11')"
              v-model="selected.dqb11"
              type="text"
            />
            <InputText
              v-bind="getProps('dqb12', dnaclass)"
              v-tooltip.focus="getTooltip('dqb12')"
              v-model="selected.dqb12"
              type="text"
            />
          </Field>
          <Field label="DQA1" :bind="getFixedProps(dnaWidth)">
            <InputText
              v-bind="getProps('dqa11', dnaclass)"
              v-tooltip.focus="getTooltip('dqa11')"
              v-model="selected.dqa11"
              type="text"
            />
            <InputText
              v-bind="getProps('dqa12', dnaclass)"
              v-tooltip.focus="getTooltip('dqa12')"
              v-model="selected.dqa12"
              type="text"
            />
          </Field>
          <Field label="DPB1" :bind="getFixedProps(dnaWidth)">
            <InputText
              v-bind="getProps('dpb11', dnaclass)"
              v-tooltip.focus="getTooltip('dpb11')"
              v-model="selected.dpb11"
              type="text"
            />
            <InputText
              v-bind="getProps('dpb12', dnaclass)"
              v-tooltip.focus="getTooltip('dpb12')"
              v-model="selected.dpb12"
              type="text"
            />
          </Field>
          <Field label="DPA1" :bind="getFixedProps(dnaWidth)">
            <InputText
              v-bind="getProps('dpa11', dnaclass)"
              v-tooltip.focus="getTooltip('dpa11')"
              v-model="selected.dpa11"
              type="text"
            />
            <InputText
              v-bind="getProps('dpa12', dnaclass)"
              v-tooltip.focus="getTooltip('dpa12')"
              v-model="selected.dpa12"
              type="text"
            />
          </Field>
          <Field
            label="Date of last DNA test"
            :bind="getFixedProps(dnaBottomWidth)"
          >
            <Calendar
              v-bind="getProps('datetestdna', medicalclass)"
              v-tooltip.focus="getTooltip('datetestdna')"
              v-model="datetestdna"
              monthNavigator
              yearNavigator
              :yearRange="yearRange"
              @date-select="valid"
            />
          </Field>
          <Field label="DNA Laboratory" :bind="getFixedProps(dnaBottomWidth)">
            <Dropdown
              v-bind="getProps('dnalaboratory', medicalclass)"
              v-tooltip.focus="getTooltip('dnalaboratory')"
              v-model="selected.dnalaboratory"
              :options="dnaLaboratory"
              optionLabel="instid"
              optionValue="instid"
              @change="valid"
            />
          </Field>
        </div>
      </TabPanel>
    </TabView>
    <Button
      :disabled="disable"
      label="Save"
      class="p-button-rounded p-button-success"
      @click="save"
    />
    <br />
    <br />
    <span style="color: red;">*</span> Required field
  </ViewTemplate>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import InputText from "primevue/inputtext";
import Calendar from "primevue/calendar";
import Dropdown from "primevue/dropdown";
import Message from "primevue/message";
import Dialog from "primevue/dialog";
import Field from "@/components/Field.vue";
import EmdAddressService from "@/service/emdAddress";
import AtaTmpPat from "@/model/ataTmpPat";
import AtaTmpPatUtil from "@/util/ataTmpPatUtil";
import DnaValidation from "@/model/dnaValidation";
import Ethnicity from "@/model/enums/ethnicity";
import Gender from "@/model/enums/gender";
import Abo from "@/model/enums/abo";
import Rh from "@/model/enums/rh";
import DgEmdis from "@/model/enums/dgemdis";
import SearchScope from "@/model/enums/searchScope";
import Dispha from "@/model/enums/dispha";
import Cmv from "@/model/enums/cmv";
import Status from "@/model/enums/status";
import EmdAddress from "@/model/emdAddress";
import PatientMode from "@/util/enums/patientMode";
import Alert from "@/util/alert";
import { namespace } from "vuex-class";
const ataTmpPat = namespace("ataTmpPat");

@Options({
  components: {
    Field,
    TabView,
    TabPanel,
    InputText,
    Calendar,
    Dropdown,
    Message,
    Dialog
  },
  watch: {
    "selected.personalnumber": {
      handler: "parsePersonalnumber"
    }
  }
})
export default class EditPatient extends Vue {
  @ataTmpPat.State
  selected!: AtaTmpPat;
  @ataTmpPat.State
  validationMap!: Map<string, string[]>;
  @ataTmpPat.State
  alerts!: Alert[];
  @ataTmpPat.State
  mode!: PatientMode;

  Gender = Gender;
  Ethnicity = Ethnicity;
  Abo = Abo;
  Rh = Rh;
  DgEmdis = DgEmdis;
  SearchScope = SearchScope;
  Dispha = Dispha;
  Cmv = Cmv;
  Status = Status;
  dnaLaboratory = [] as EmdAddress[];
  PatientMode = PatientMode;

  idclass = "p-col-6 p-mb-2";
  medicalclass = "p-col-3 p-mb-2";
  dnaclass = "p-col-2 p-mb-2 p-mr-2";
  idWidth = "150px";
  medicalWidth = "250px";
  dnaWidth = "100px";
  dnaBottomWidth = "200px";

  async created() {
    this.valid();
    await EmdAddressService.dnaLaboratory().then(
      resp => (this.dnaLaboratory = resp.data)
    );
  }

  getProps(field: string, css: string) {
    let disabled = field === "patientid1" ? !this.disable : this.disable;
    disabled =
      this.mode === PatientMode.EDIT && field === "personalnumber" // Disable personal number when EDIT
        ? true
        : disabled;
    return {
      disabled,
      class: this.getclass(field, css),
      id: field
    };
  }

  getFixedProps(width: string) {
    return {
      class: "p-col-fixed",
      style: "width:" + width
    };
  }

  getTooltip(field: string) {
    return this.validationMap.get(field);
  }

  getclass(val: string, def: string) {
    return [def, { "p-invalid": this.validationMap.get(val) }];
  }

  get disable() {
    return (
      this.mode === PatientMode.NONE ||
      this.mode === PatientMode.OTHER_TC ||
      this.mode === PatientMode.DUPLICATE ||
      this.mode === PatientMode.EDIT_CONFIRM
    );
    // return false;
  }

  get yearRange() {
    return `${new Date().getFullYear() - 150}:${new Date().getFullYear()}`;
  }

  closingEditPatDialog(edit: boolean) {
    if (edit) this.setMode(PatientMode.EDIT);
    else this.setMode(PatientMode.NONE);
    this.valid();
  }

  @ataTmpPat.Mutation("SET_MODE")
  setMode!: (mode: PatientMode) => void;

  @ataTmpPat.Mutation("ADD_ERROR")
  addError!: ({ field, msg }: { field: string; msg: string }) => void;

  @ataTmpPat.Mutation("RESET_ERROR")
  resetValidation!: () => void;

  async confirm() {
    await this.patientExists(this.selected.patientid1);
    this.valid();
  }

  async valid() {
    this.resetValidation();
    if (!this.selected.patientid1) {
      this.addError({ field: "patientid1", msg: "" });
      this.setMode(PatientMode.NONE);
    }
    if (this.disable) return;

    // this.parseFields();
    // Id tab
    if (!this.selected.lastname || this.selected.lastname.length == 0)
      this.addError({ field: "lastname", msg: "Required" });
    else if (this.selected.lastname.length > 30)
      this.addError({ field: "lastname", msg: "Max 30 characters" });

    if (!this.selected.firstname || this.selected.firstname.length == 0)
      this.addError({ field: "firstname", msg: "Required" });
    else if (this.selected.firstname.length > 30)
      this.addError({ field: "firstname", msg: "Max 30 characters" });

    if (!this.birthdate) this.addError({ field: "birthdate", msg: "Required" });
    else if (!(this.birthdate instanceof Date))
      this.addError({ field: "birthdate", msg: "Not a date" });

    if (
      (!this.selected.gender && this.selected.gender != 0) ||
      this.selected.gender == 2
    )
      this.addError({ field: "gender", msg: "Required" });

    if (this.selected.weight != null && isNaN(+this.selected.weight))
      this.addError({ field: "weight", msg: "Must be number" });

    if (this.selected.height != null && isNaN(+this.selected.height))
      this.addError({ field: "height", msg: "Must be number" });

    // Medical tab
    if (!this.selected.dgemdis || this.selected.dgemdis == 0)
      this.addError({ field: "dgemdis", msg: "Required" });

    if (this.dateofdg && !(this.dateofdg instanceof Date))
      this.addError({ field: "dateofdg", msg: "Not a date" });
    if (this.statusdate && !(this.statusdate instanceof Date))
      this.addError({ field: "statusdate", msg: "Not a date" });

    if (!this.selected.searchscope || this.selected.searchscope == 0)
      this.addError({ field: "searchscope", msg: "Required" });

    // DNA tab
    if (this.datetestdna && !(this.datetestdna instanceof Date))
      this.addError({ field: "datetestdna", msg: "Not a date" });

    await this.validateDna();
  }

  parseFields() {
    this.selected.a1 = this.parseDna(this.selected.a1);
    this.selected.a2 = this.parseDna(this.selected.a2);
    this.selected.b1 = this.parseDna(this.selected.b1);
    this.selected.b2 = this.parseDna(this.selected.b2);
    this.selected.c1 = this.parseDna(this.selected.c1);
    this.selected.c2 = this.parseDna(this.selected.c2);
    this.selected.drb11 = this.parseDna(this.selected.drb11);
    this.selected.drb12 = this.parseDna(this.selected.drb12);
    this.selected.drb31 = this.parseDna(this.selected.drb31);
    this.selected.drb32 = this.parseDna(this.selected.drb32);
    this.selected.drb41 = this.parseDna(this.selected.drb41);
    this.selected.drb42 = this.parseDna(this.selected.drb42);
    this.selected.drb51 = this.parseDna(this.selected.drb51);
    this.selected.drb52 = this.parseDna(this.selected.drb52);
    this.selected.dqb11 = this.parseDna(this.selected.dqb11);
    this.selected.dqb12 = this.parseDna(this.selected.dqb12);
    this.selected.dqa11 = this.parseDna(this.selected.dqa11);
    this.selected.dqa12 = this.parseDna(this.selected.dqa12);
    this.selected.dpb11 = this.parseDna(this.selected.dpb11);
    this.selected.dpb12 = this.parseDna(this.selected.dpb12);
    this.selected.dpa11 = this.parseDna(this.selected.dpa11);
    this.selected.dpa12 = this.parseDna(this.selected.dpa12);
  }

  validPersonalnumber() {
    const pn = this.selected.personalnumber;
    if (!/^\d{6}[-A+]{1}\d{3}[\w\d]{1}$/g.test(pn)) return false;

    const s = "0123456789ABCDEFHJKLMNPRSTUVWXY";
    if (!s.includes(pn.charAt(10))) return false;
    const c = pn.slice(0, 6) + pn.slice(7, 10);
    if (isNaN(+c)) return false;
    if (+c % 31 >= s.length) return false;
    return s[+c % 31] == pn.charAt(10);
  }

  async validateDna() {
    const dna: DnaValidation = {
      a1: this.selected.a1,
      a2: this.selected.a2,
      b1: this.selected.b1,
      b2: this.selected.b2,
      c1: this.selected.c1,
      c2: this.selected.c2,
      drb11: this.selected.drb11,
      drb12: this.selected.drb12,
      drb31: this.selected.drb31,
      drb32: this.selected.drb32,
      drb41: this.selected.drb41,
      drb42: this.selected.drb42,
      drb51: this.selected.drb51,
      drb52: this.selected.drb52,
      dqb11: this.selected.dqb11,
      dqb12: this.selected.dqb12,
      dqa11: this.selected.dqa11,
      dqa12: this.selected.dqa12,
      dpb11: this.selected.dpb11,
      dpb12: this.selected.dpb12,
      dpa11: this.selected.dpa11,
      dpa12: this.selected.dpa12
    };
    const errors = this.validationMap.size;
    let empty = true;
    Object.entries(dna).forEach(([key, value]) => {
      if (value) empty = false;
      if (!this.validDna(value))
        this.addError({
          field: key,
          msg: "Invalid Dna"
        });
    });
    if (!empty && errors == this.validationMap.size) {
      await this.dnaValidation(dna);
    }
  }

  validDna(dna: string): boolean {
    if (!dna || dna.length == 0) return true;
    if (!/^([\dA-Z]{2,5}:){0,3}[\dA-Z]{2,5}$/g.test(dna)) return false;
    return true;
  }

  parseDna(dna: string): string {
    dna = dna
      .trimStart()
      .toUpperCase()
      .replaceAll(" ", ":")
      .replaceAll(/[^\d\w:]/g, "");
    while (dna.includes("::")) dna = dna.replaceAll("::", ":");
    //TODO 2 -> 02
    return dna;
  }

  parsePersonalnumber() {
    if (this.selected.personalnumber == undefined) return; //When pat is saved, selected is removed which fire watcher one last time
    if (this.validPersonalnumber()) {
      const pn = this.selected.personalnumber;

      this.selected.gender = ((+pn.slice(8, 10) % 2) + 1) % 2; //Odd => 0 (Male), Even => 1 (Female)

      let century = "";
      switch (pn.charAt(6)) {
        case "+":
          century = "18";
          break;
        case "-":
          century = "19";
          break;
        case "A":
          century = "20";
          break;
      }
      this.selected.birthdate = new Date(
        +(century + pn.slice(4, 6)),
        +pn.slice(2, 4) - 1,
        +pn.slice(0, 2)
      );
    }
  }

  utcDate(date: Date | undefined): Date | undefined {
    if (date instanceof Date) {
      date = new Date(
        Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
      );
    }
    return date;
  }

  get birthdate() {
    return AtaTmpPatUtil.parseDate(this.selected.birthdate);
  }

  set birthdate(value: Date | undefined) {
    this.selected.birthdate = this.utcDate(value);
  }

  get dateofdg() {
    return AtaTmpPatUtil.parseDate(this.selected.dateofdg);
  }

  set dateofdg(value) {
    this.selected.dateofdg = this.utcDate(value);
  }

  get statusdate() {
    return AtaTmpPatUtil.parseDate(this.selected.statusdate);
  }

  set statusdate(value) {
    this.selected.statusdate = this.utcDate(value);
  }

  get datetestdna() {
    return AtaTmpPatUtil.parseDate(this.selected.datetestdna);
  }

  set datetestdna(value) {
    this.selected.datetestdna = this.utcDate(value);
  }

  @ataTmpPat.Action
  patientExists!: (tNumber: string) => void;

  @ataTmpPat.Action
  dnaValidation!: (dna: DnaValidation) => void;

  @ataTmpPat.Action
  save!: () => void;
}
</script>

<style scoped>
.p-button-success {
  background: #3a8941;
}

.p-button-success:hover {
  background: #236929;
}

.p-dropdown {
  padding: 0;
}

.p-calendar {
  padding: 0;
}
</style>
