
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    label: String,
    bind: Object,
    required: {
      type: Boolean,
      required: false
    }
  }
})
export default class Field extends Vue {
  label = "";
  bind!: object;
  required = false;
}
