<template>
  <div class="p-col-12 p-grid">
    <label v-bind="bind">
      {{ label }}<span v-if="required" style="color: red;">*</span>
    </label>
    <slot />
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    label: String,
    bind: Object,
    required: {
      type: Boolean,
      required: false
    }
  }
})
export default class Field extends Vue {
  label = "";
  bind!: object;
  required = false;
}
</script>

<style scoped></style>
